import { $path } from 'remix-routes';

import {
  type DtoPage,
  type DtoPageSection,
  type EnumsPageName,
} from '@lp-lib/api-service-client/public';

import { useAppAnalytics } from '../../../src/analytics/app/identifiable';
import { CreateCustomGameButton } from '../../../src/components/Game/UGC/Nav';
import { useUGCAnalytics } from '../../../src/components/Game/UGC/utils';
import { useMyOrganizationFeatureChecker } from '../../../src/components/Organization/hooks/organization';
import { useFeatureQueryParam } from '../../../src/hooks/useFeatureQueryParam';
import { useNavigateToSection } from '../DynamicPage/PageSectionContainer';
import { PageDisplayName } from '../DynamicPage/utils';
import {
  PublicLibraryNav,
  PublicLibraryNavSearchBar,
} from './PublicLibraryNav';

export function PublicLibraryNavCreateCustomGame() {
  const featureChecker = useMyOrganizationFeatureChecker();
  const analytics = useUGCAnalytics();
  const ugcEnabled = useFeatureQueryParam('game-pack-ugc');

  if (!featureChecker.hasUnlimitedOndGameUGCAccess() && !ugcEnabled) {
    return null;
  }
  return <CreateCustomGameButton width='w-40' analytics={analytics} />;
}

export function HomePublicLibraryNav(props: {
  pageName: EnumsPageName;
  page: DtoPage;
  isSectionSelected?: (section: DtoPageSection) => boolean;
}) {
  const { pageName, page, isSectionSelected } = props;

  const navigateToSection = useNavigateToSection($path('/home'));
  const analytics = useAppAnalytics();

  return (
    <PublicLibraryNav
      page={page}
      onSectionClick={(section, index) => {
        analytics.trackSectionNavClicked({
          pageName: PageDisplayName(pageName),
          sectionId: section.id,
          sectionTitle: section.title,
          index,
        });
        navigateToSection(section);
      }}
      isSectionSelected={isSectionSelected}
      actions={
        <div className='flex-none flex items-center gap-3'>
          <PublicLibraryNavSearchBar
            targetPath={$path('/home/search')}
            analytics={analytics}
          />
          <PublicLibraryNavCreateCustomGame />
        </div>
      }
      className='pr-5'
    />
  );
}
